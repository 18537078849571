@use '../../../sass/variables';

div.container.block-links-wrapper {
    max-width: 1200px !important;
    @media only screen and (max-width: 1080px) and (orientation: landscape) {
        max-width: 980px !important;
    }
}

.block-links {
    padding: 60px 0;

    p {
        font-size: 16px !important;
    }

    .block-links-wrapper {
        padding-left: 0;
        padding-right: 0;

        .slick-slider {
            touch-action: auto;
            overflow: visible;

            .slick-list {
                overflow: hidden;
            }
        }

        .slick-prev,
        .slick-next {
            cursor: pointer;
            height: 103px;
            margin-top: -51.5px;
            opacity: 0.7;
            overflow: hidden;
            position: absolute;
            text-indent: -9999px;
            top: 50%;
            transition: opacity 0.3s, visibility 0.3s;
            visibility: visible;
            width: 61px;
            z-index: 1;

            &.slick-disabled {
                opacity: 0;

                &:hover,
                &:focus {
                    opacity: 0;
                }
            }

            &::before {
                line-height: 103px;
            }

            &:hover,
            &:focus {
                color: variables.$black;
                opacity: 1;
            }
        }

        .slick-prev {
            left: -55px;
        }

        .slick-next {
            right: -55px;
        }
    }

    &.module-after-homehero {
        z-index: 2;

        .slick-slider {
            .slick-list {
                height: 412px;
                overflow-y: hidden;
            }
        }

        &.initialised {
            .slick-slider {
                width: calc(100% - 80px);
                margin: 0 auto;

                .slick-list {
                    height: 430px;
                    overflow-y: hidden;
                }
            }
        }

        .block-links-wrapper {
            .slick-slider {
                padding: 20px 0;
            }
        }
    }
}

.block-links .block-links-wrapper .block-link-block {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
}

.block-links .block-links-wrapper .block-link-block .block-link {
    height: 275px;
    position: relative;
    overflow: hidden;
    background-color: variables.$white;
    border: 2px solid darken(variables.$breadCrumbBackgroundColor, 5%);
    z-index: 1;
    transition: border-color 0.5s ease;

    &:hover {
        border-color: variables.$proBlue;
    }

    .block-link-text {
        background-color: variables.$white;
        color: variables.$proDarkBlue;
        padding: 15px 20px;
        overflow: hidden;
        position: absolute;
        width: 100%;
        min-height: 120px;
        max-height: 200px;
        top: 0;
        left: 0;
        transition: opacity 0.5s ease;
        z-index: 3;

        &::before {
            background-color: variables.$assetHighlightsGrey;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 0.5s ease;
            width: 100%;
            z-index: -1;
        }
    }

    &::before {
        background-color: variables.$assetHighlightsGrey;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.5s ease;
        width: 100%;
        z-index: 2;
    }

    &:hover,
    &:focus {
        .block-link-text {
            &::before {
                content: ' ';
                opacity: 1;
                z-index: -1;
            }
        }

        &::before {
            opacity: 0.75;
        }
    }
}

.block-links .block-links-wrapper .block-link-block .block-link .block-link-bottom {
    top: auto;
    bottom: 0;
}

.block-links .block-links-wrapper .block-link-block .block-link .block-link-text h3 {
    color: variables.$proDarkBlue;
    margin-bottom: 5px;
    max-width: 80%;
    font-size: 20px;
    line-height: 1.2em;
}

.block-links .block-links-wrapper .block-link-block.col-md-3 .block-link .block-link-text h3 {
    font-size: 18px;
}

.block-links .block-links-wrapper .block-link-block .block-link .block-link-text p {
    font-size: 12px;
    line-height: 1.8em;
}

.block-links .block-links-wrapper .block-link-block .block-link .block-link-text p:last-of-type {
    margin-bottom: 0;
}

.block-links:not(.block-links-slider) + .block-links {
    margin-top: -73px;
    padding-top: 0;
}

.block-links {
    &.block-links-slider {
        .block-links-wrapper {
            .block-link-slide {
                float: left;
                height: 412px;
                margin-right: 24px;
                position: relative;
                width: 450px;

                .block-link {
                    height: 100%;
                }
            }
        }
    }

    &.module-after-homehero {
        .block-links-wrapper {
            height: 100%;
            .block-link-title {
                font-size: 18px !important;
            }

            .block-link-subtitle {
                bottom: 0;
                padding-bottom: 10px;
                position: absolute;
            }

            .block-link-block {
                .block-link {
                    height: 300px;

                    .block-link-text {
                        height: 175px;
                    }
                }
            }

            .block-link-slide {
                margin: 0 14px;
                width: 344px;
            }

            .slick-list {
                height: 500px !important;
                @media only screen and (min-width: 810px) and (orientation: portrait) {
                    margin: 30px;
                }
            }

            .block-link-inner {
                height: 500px !important;
                width: 336px !important;

                @media only screen and (max-width: 810px) and (orientation: portrait) {
                    width: 342px !important;
                }
                @media only screen and (max-width: 1080px) and (orientation: landscape) {
                    width: 302px !important;
                }
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                }

                .block-link-text {
                    min-height: 220px !important;
                    height: auto;

                    .block-link-title {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .block-links {
        &.module-after-homehero {
            .block-links-wrapper {
                .slick-prev {
                    left: -25px;
                }

                .slick-next {
                    right: -25px;
                }
                .slick-slider {
                    width: 100%;
                    background: none;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 25px;

                    .block-link-slide {
                        margin: 0px;
                        width: 310px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .block-links .block-links-wrapper .block-link-block {
        float: left;
        margin-bottom: 25px;
        width: 50%;
    }
}

@media only screen and (max-width: 991px) {
    .block-links {
        &.module-after-homehero {
            .block-links-wrapper {
                .slick-slider {
                    .block-link-slide {
                        margin: 0px;
                        width: 350px;

                        &.block-link-first-slider {
                            width: 350px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .block-links .block-links-wrapper .block-link-block {
        float: none;
        width: 100%;
    }
    .block-links {
        &.module-after-homehero {
            .block-links-wrapper {
                .slick-slider {
                    .block-link-slide {
                        width: 345px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .block-links {
        &.block-links-slider {
            .block-links-wrapper {
                .block-link-slide {
                    margin: 0 !important;
                    width: 100%;

                    &.block-link-first-slider {
                        width: 100%;
                    }
                }
            }
        }

        &.module-after-homehero {
            /* No peeking on mobile */
            margin-top: 0px;

            .block-links-wrapper {
                .slick-slider {
                    padding: 40px 0;
                }
            }
        }
    }
}

.block-links-wrapper ul.slick-dots {
    display: flex !important;
    justify-content: center !important;
}
